
import { computed, defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { StepperComponent } from "@/assets/ts/components";
import { useForm, Field } from "vee-validate";
import * as Yup from "yup";
import Step1 from "@/views/reusable/buy-insurance/house-insurance/partials/OwnBuildingAdditionStep1.vue";
import Step2 from "@/views/reusable/buy-insurance/house-insurance/partials/OwnBuildingAdditionStep2.vue";
import Step3 from "@/views/reusable/buy-insurance/house-insurance/partials/OwnBuildingAdditionStep3.vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import TotalPremiumWidget from "@/components/packages/TotalPremiumWidget.vue";
import GoBackButton from "@/components/reusable/GoBackButton.vue";
import ResusableFunctions from "@/composables/ReusableFunctions";

// eslint-disable-next-line no-redeclare
interface Step1 {
  startDate: string;
  buildingType: string;
  state: string;
  localGovernmentArea: string;
  ward: string;
  streetAddress: string;
  buildingReinstatementValue: string;
  lossOfRentValue: string;
  imagesOfBuilding: string;
}

// eslint-disable-next-line no-redeclare
interface Step2 {
  houseHoldGoodsAndPersonalEffectsValue: string;
  cashValue: string;
  thirdPartyLiabilityLimit: string;
  schedule: string;
  phoneDetails: {
    imageOfReceipt: string;
    serialNumber: string;
    value: string;
  };
  laptopOrTabletDetails: {
    imageOfReceipt: string;
    serialNumber: string;
    value: string;
  };
  jewelryDetails: {
    brand: string;
    imageOfReceipt: string;
    value: string;
  };
  cameraDetails: {
    brand: string;
    imageOfReceipt: string;
    value: string;
  };
  musicalInstrumentDetails: {
    brand: string;
    imageOfReceipt: string;
    value: string;
  };
  inverterDetails: {
    imageOfReceipt: string;
    serialNumber: string;
    value: string;
  };
}

// eslint-disable-next-line no-redeclare
interface Step3 {
  generators: {
    generatorValue: string;
    generatorBrand: string;
    generatorPhoto: string;
    generatorReceiptPhoto: string;
  };
}

interface CreateOwnedBuilding extends Step1, Step2, Step3 {}

export default defineComponent({
  name: "addition-owned-building-house-insurance",
  components: { Field, Step1, Step2, Step3, TotalPremiumWidget, GoBackButton },
  data() {
    return {
      displayForm: true,
      displayError: false,
      policies: [],
      policySummary: {
        packages: [],
        expiryDate: "",
      },
      policyDetails: [],
      totalPremium: 0.0 as number,
      underwriters: [],
      buildingReinstatementValueRate: 0.001,
      buildingReinstatementRatePublicId: "",
      lossOfRentValueRate: 0.001,
      lossOfRentRatePublicId: "",
      personalEffectsRate: 0.045,
      personalEffectsRatePublicId: "",
      cashValueRate: 0.006,
      cashValueRatePublicId: "",
      thirdPartLiabilityValueRate: 0.00075,
      thirdPartLiabilityValueRatePublicId: "",
      generatorRate: 0.0025,
      generatorRatePublicId: "",
      phoneRate: 0.05,
      phoneRatePublicId: "",
      laptopRate: 0.02,
      laptopRatePublicId: "",
      cameraRate: 0.01,
      cameraRatePublicId: "",
      inverterRate: 0.0025,
      inverterRatePublicId: "",
      musicalInstrumentRate: 0.01,
      musicalInstrumentRatePublicId: "",
      jewelryRate: 0.01,
      jewelryRatePublicId: "",
      policy: "" as string,
      publicId: this.$route.params.publicId as string,
      housePolicyId: "",
      underwriter: "",
      premiumCalculationRoute: "" as string,
      getPackage: {
        publicId: "",
      },
      packageId: "",
    };
  },
  setup() {
    const { findObjectByKeyValue } = ResusableFunctions();

    const _stepperObj = ref<StepperComponent | null>(null);
    const horizontalWizardRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);
    // alert(horizontalWizardRef.value);

    const formData = ref<CreateOwnedBuilding>({
      startDate: "",
      buildingType: "",
      state: "",
      localGovernmentArea: "",
      ward: "",
      streetAddress: "",
      buildingReinstatementValue: "",
      lossOfRentValue: "",
      imagesOfBuilding: "",
      houseHoldGoodsAndPersonalEffectsValue: "",
      cashValue: "",
      thirdPartyLiabilityLimit: "",
      schedule: "",
      phoneDetails: {
        imageOfReceipt: "",
        serialNumber: "",
        value: "",
      },
      laptopOrTabletDetails: {
        imageOfReceipt: "",
        serialNumber: "",
        value: "",
      },
      jewelryDetails: {
        brand: "",
        imageOfReceipt: "",
        value: "",
      },
      cameraDetails: {
        brand: "",
        imageOfReceipt: "",
        value: "",
      },
      musicalInstrumentDetails: {
        brand: "",
        imageOfReceipt: "",
        value: "",
      },
      inverterDetails: {
        imageOfReceipt: "",
        serialNumber: "",
        value: "",
      },
      generators: {
        generatorValue: "",
        generatorBrand: "",
        generatorPhoto: "",
        generatorReceiptPhoto: "",
      },
    });

    onMounted(() => {
      setCurrentPageBreadcrumbs("Owned Building", [
        "Insurance Packages",
        "House Insurance Policy",
        "Addition",
      ]);

      _stepperObj.value = StepperComponent.createInsance(
        horizontalWizardRef.value as HTMLElement
      );
    });

    const CreateOwnedBuildingSchema = [
      Yup.object({
        buildingType: Yup.mixed()
          .required()
          .label("Type of building"),
        state: Yup.string()
          .required()
          .label("State"),
        localGovernmentArea: Yup.string()
          .required()
          .label("L.G.A"),
        ward: Yup.string()
          .required()
          .label("Town/City"),
        streetAddress: Yup.string()
          .required()
          .label("Street address"),
        buildingReinstatementValue: Yup.string()
          .required()
          .label("Building reinstatement value"),
        lossOfRentValue: Yup.string()
          .required()
          .label("Loss of rent value"),
        imagesOfBuilding: Yup.mixed()
          .required()
          .label("Photo of building"),
      }),
      Yup.object({
        houseHoldGoodsAndPersonalEffectsValue: Yup.string()
          .required()
          // .min(10000)
          .label("Contents value"),
        cashValue: Yup.string()
          .notRequired()
          // .min(10000)
          .label("Cash value"),
        thirdPartyLiabilityLimit: Yup.string()
          .notRequired()
          // .min(10000)
          .label("Third-party liability value"),
      }),
      Yup.object({
        generatorValue: Yup.string()
          .notRequired()
          .label("Generator value"),
        generatorBrand: Yup.mixed()
          .notRequired()
          .label("Generator brand"),
        generatorPhoto: Yup.mixed()
          .notRequired()
          .label("Photo of generator"),
        generatorReceiptPhoto: Yup.mixed()
          .notRequired()
          .label("Photo of generator receipt"),
      }),
    ];

    const currentSchema = computed(() => {
      return CreateOwnedBuildingSchema[currentStepIndex.value];
    });

    const { resetForm, handleSubmit } = useForm<Step1 | Step2 | Step3>({
      // validationSchema: currentSchema,
    });

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return;
      }

      return _stepperObj.value.totatStepsNumber;
    });

    resetForm({
      values: {
        ...formData.value,
      },
    });

    const handleStep = handleSubmit((values) => {
      formData.value = {
        ...formData.value,
        ...values,
      };

      currentStepIndex.value++;

      if (!_stepperObj.value) {
        return;
      }
      _stepperObj.value.goNext();

      const grandPremium = document.getElementById(
        "total-premium-field"
      ) as HTMLInputElement;

      // Append total premium hidden field
      grandPremium.value = variables.totalPremiumValue() as any;
    });

    const previousStep = () => {
      if (!_stepperObj.value) {
        return;
      }

      currentStepIndex.value--;

      _stepperObj.value.goPrev();
    };

    return {
      horizontalWizardRef,
      previousStep,
      handleStep,
      totalSteps,
      currentStepIndex,
      findObjectByKeyValue,
    };
  },
  created() {
    // Get owned building house insurance policies only
    this.getPolicies();

    // Get the rates for house bulding package
    // this.getHouseBuildingRates();

    //Set page title
    document.title =
      "Addition: Owned Building Householders Insurance | " +
      process.env.VUE_APP_TITLE;
  },
  methods: {
    getPolicies() {
      if (this.publicId !== undefined && this.publicId !== null) {
        this.policy = this.publicId;
        this.getPolicyInformation(this.publicId);
      }

      //Fetch client policies
      // if (JwtService.getToken()) {
      //   ApiService.setHeader();
      //   ApiService.get(`${variables.POLICIES_ROUTE}/householder/OWN`)
      //     .then(({ data }) => {
      //       //Assign data to props
      //       if (data.data != "") {
      //         this.displayError = false;
      //         this.policies = data.data;

      //         if (this.publicId !== undefined && this.publicId !== null) {
      //           this.policy = this.publicId;
      //           this.getPolicyInformation(this.publicId);
      //         }
      //       } else {
      //         this.displayError = true;
      //       }
      //     })
      //     .catch(function(error) {
      //       variables.toastAlert(error.response.data.error, "error");
      //     });
      // }
    },
    getHouseBuildingRates() {
      //Fetch list of underwriters for house building insurance package
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.HOUSE_BUILDING_RATE_ROUTE)
          .then(({ data }) => {
            //Assign data to form fields
            this.buildingReinstatementValueRate =
              data.data.buildingReinstatementValue.value;
            this.buildingReinstatementRatePublicId =
              data.data.buildingReinstatementValue.id;
            this.lossOfRentValueRate = data.data.lossOfRent.value;
            this.lossOfRentRatePublicId = data.data.lossOfRent.id;
            this.personalEffectsRate = data.data.personalEffects.value;
            this.personalEffectsRatePublicId = data.data.personalEffects.id;
            this.cashValueRate = data.data.cash.value;
            this.cashValueRatePublicId = data.data.cash.id;
            this.thirdPartLiabilityValueRate =
              data.data.thirdPartyLiabilityCover.value;
            this.thirdPartLiabilityValueRatePublicId =
              data.data.thirdPartyLiabilityCover.id;
            this.generatorRate = data.data.generatorOrInverter.value;
            this.generatorRatePublicId = data.data.generatorOrInverter.id;
            this.phoneRate = data.data.phone.value;
            this.phoneRatePublicId = data.data.phone.id;
            this.laptopRate = data.data.laptopOrTablet.value;
            this.laptopRatePublicId = data.data.laptopOrTablet.id;
            this.cameraRate = data.data.camera.value;
            this.cameraRatePublicId = data.data.camera.id;
            this.inverterRate = data.data.generatorOrInverter.value;
            this.inverterRatePublicId = data.data.generatorOrInverter.id;
            this.musicalInstrumentRate = data.data.musicalInstrument.value;
            this.musicalInstrumentRatePublicId = data.data.musicalInstrument.id;
            this.jewelryRate = data.data.jewelry.value;
            this.jewelryRatePublicId = data.data.jewelry.id;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getPolicyInformation(value) {
      this.housePolicyId = value;
      this.premiumCalculationRoute = `${variables.CART_ROUTE}/premium/addition/${value}`;

      //Fetch individual vehicle policy information
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.POLICIES_ROUTE}/${value}`)
          .then(({ data }) => {
            //Assign data to variables
            if (data.data != "") {
              this.policySummary = data?.data?.policySummary;
              this.policyDetails = data?.data?.items;
              this.totalPremium = data?.data?.policySummary.premium;

              this.displayForm = true;
              this.getPackage = this.findObjectByKeyValue(
                this.policySummary?.packages,
                "brokerReference",
                "PACK-1"
              );

              this.packageId = this.getPackage?.publicId;
            }
          })
          .catch(function(error) {
            console.log(error);
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    formSubmit(e) {
      e.preventDefault();
      const submitButton1 = document.getElementById("home_submit_btn");

      if (submitButton1) {
        // Activate indicator
        submitButton1.setAttribute("data-kt-indicator", "on");

        const form = document.forms[0];
        const formData = new FormData(form);
        // formData.append("package", "BUI");
        formData.append("package", "HOO");
        formData.append("packageType", "OWN");
        formData.append("businessClass", "HOM");
        formData.append("businessType", "HOU");

        if (JwtService.getToken()) {
          ApiService.setHeader();
          ApiService.post(
            variables.CART_ADDITION_ROUTE + formData.get("housePolicyId"),
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
            .then((response) => {
              variables.toastAlert(response.data.data.message, "success");
              this.$router.push({
                name: "insurance-packages-cart-debit-note",
                params: {
                  publicId: response.data.data.cartItemPublicId,
                },
              });
            })
            .catch(function(error) {
              variables.toastAlert(error.response.data.error, "error");
            });
        }
      }
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
  },
});
